// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { Row, Col, Container } from 'reactstrap';
import Logo from '../../images/logo-icon.png'
import { NavbarBrand, } from 'reactstrap';
import { FaFacebook, FaTwitter, FaLinkedinIn } from 'react-icons/fa';




const Footer = (props) => {
    let pathname = props.pathName;
    return (

        <footer >
            <Container>
                <Row>
                    <Col md="9">
                        <div className="footer_nav">
                            <ul className="footer_menu">
                                <li>
                                    <a href="/Enterprise" active={pathname === '/Enterprise'}>Enterprise</a>
                                </li>
                                <li>
                                    <a href="/Startups" active={pathname === '/Startups'}>Startups</a>
                                </li>
                                <li>
                                    <a href="/Diversity" active={pathname === '/Diversity'}>Diversity</a>
                                </li>
                                <li>
                                    <a href="/Consultants" active={pathname === '/Consultants'}>Consultants</a>
                                </li>
                                <li>
                                    <a href="/Jobs" active={pathname === '/job/'}>Jobs</a>
                                </li>
                                
                                <li>
                                    <a href="/Company" active={pathname === '/Company'}>Company</a>
                                </li>
                                {/* <li>
                                    <a href="" active={pathname === '/'}> Privacy Policy</a>
                                </li> */}
                            </ul>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="footer_logo">
                            <NavbarBrand href="/">
                                <a href="/">
                                    <div className="logo_left">
                                        <img src={Logo} alt="img" />
                                    </div>
                                    <div className="logo_right">
                                        <h2>Trident</h2>
                                        <p>Trident Consulting</p>
                                    </div>
                                </a>
                            </NavbarBrand>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="10" sm="12">
                        <p className="footer_text">© {new Date().getFullYear()}, Trident. All Rights Reserved.</p>
                    </Col>
                    <Col md="2" sm="12">
                        <div className="footer_icon">
                        <a href="https://www.linkedin.com/company/tridentconsulting/" target="blank">
                                <FaLinkedinIn size={20} />
                            </a>
                            <a href="https://www.facebook.com/pages/category/Business-Consultant/Trident-Consulting-152255308129297/" target="blank">
                                <FaFacebook size={20} />
                            </a>
                            <a href="https://twitter.com/Tridentconsult" target="blank">
                                <FaTwitter size={20} />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
export default Footer
